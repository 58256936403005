'use strict';

(function() {

function authInterceptor($rootScope, $q, $cookies, $injector, Util, appConfig) {
  var state;
  return {
    // Add authorization token to headers
    request(config) {
      config.headers = config.headers || {};
      if (config.url.includes(appConfig.api.url)) {
        config.headers['x-access-token'] = $cookies.get('token');
        config.headers.key = appConfig.api.key;
      }
      return config;
    },

    // Intercept server data
    response(response) {
      if (response.config.url.includes(appConfig.api.url)) {
        if (response.data.success) {
          response.data = response.data.data;
        } else {
          return $q.reject(response);
        }
      }
      return $q.resolve(response);
    },

    // Intercept 401s and redirect you to login
    responseError(response) {
      if (response.status === 401) {
        (state || (state = $injector.get('$state'))).go('logout');
        // remove any stale tokens
        $cookies.remove('token');
      }
      return $q.reject(response);
    }
  };
}

angular.module('cpformplastApp.auth')
  .factory('authInterceptor', authInterceptor);

})();
